import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [
	//首页
	{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "about" */ '../views/HomeView.vue')
	},
	//关于我们
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/about.vue')
	},
	//产品详情(门窗)
	{
		path: '/productdetail',
		name: 'productdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/productdetail.vue')
	},
	//详情(配件)
	{
		path: '/cailiao_peijian',
		name: 'cailiao_peijian',
		component: () => import( /* webpackChunkName: "about" */ '../views/cailiao_peijian.vue')
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router

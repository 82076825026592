export default {
	//中文
	msg: {
		msg1: '热线电话',
		msg2: '首页',
		msg3: '联系我们',
		msg4: '暂无数据',
		msg5: '电话',
		msg6: '邮箱',
		msg7: '材料列表>材料详情',
		msg8: '自定义尺寸',
		msg9: '请输入长度',
		msg10: '米',
		msg11: '添加',
		msg12: '删除',
		msg13: '请输入高度',
		msg14: '数量',
		msg15: '应付金额',
		msg16: '申请购买',
		msg17: '产品介绍',
		msg18: '联系地址',
		msg19: '收货人',
		msg20: '请输入收货人',
		msg21: '男',
		msg22: '女',
		msg23: '联系电话',
		msg24: '请输入联系电话',
		msg25: '是否需要送货',
		msg26: '是',
		msg27: '否',
		msg28: '所在地区',
		msg29: '请选择',
		msg30: '详细地址',
		msg31: '请填写详细地址：如道路、门牌号、小区、楼栋号等',
		msg32: '留言',
		msg33: '选填：填写内容已和卖家协商确认',
		msg34: '提交',
		msg35: '订单成功',
		msg36: '您的申请已提交成功，稍后工作人员会与您联系',
		msg37: '联系我们',
		msg38: '提交时间',
		msg39: '返回首页',
		msg40: '产品列表',
		msg41: '窗户',
		msg42: '材料',
		msg43: '查看更多',
		msg44: '产品列表>产品详情',
		msg45: '颜色',
		msg46: '选择',
		msg47: '请选择',
		msg48: '窗形',
		msg49: '铝合金',
		msg50: '自定义尺寸',
		msg51: '请输入高度',
		msg52: '请输入宽度',
		msg53: '是否需要玻璃',
		msg54: '玻璃名称',
		msg55: '请选择',
		msg56: '配件',
		msg57: '数量',
		msg58: '应付金额',
		msg59: '申请购买',
		msg60: '产品介绍',
		msg61: '选择颜色',
		msg62: '关闭',
		msg63: '确认',
		msg64: '请输入搜索关键字',
		msg65: '搜索',
		msg66: '$/米',
		msg67: '已选择',
		msg68: '选择',
		msg69: '选择窗形',
		msg70: '选择铝合金',
		msg71: '选择玻璃',
		msg72: '请输入收货人',
		msg73: '请输入联系电话',
		msg74: '返回首页',
		msg75:  "件",
		msg76:  "数量最少1个",
		msg77:  "请先选择规格",
		msg78:  "请输入自定义尺寸的长度",
		msg79:  "请输入自定义尺寸的宽度",
		msg80:  "请输入自定义尺寸的高度",
		msg81:  "请输入收货人",
		msg82:  "请输入联系电话",
		msg83:  "请选择所在地区",
		msg84:  "请填写详细地址",
		msg85:  "请选择颜色",
		msg86:  "请选择窗形",
		msg87:  "请选择铝合金",
		msg88:  "请选择玻璃",
		msg89:  "全选",
	}
}
<template>
	<div class="hello">
		<div class="login_header">
			<div class="login_header_content">
				<div class="login_header_one" @click="headerclick(1)">
					<img src="../assets/logo.jpg" alt="" />
				</div>
				<div class="header_two">
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==1}"
						@click="headerclick(1)">
						<span>{{ $t('msg.msg2') }}</span>
					</div>
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==2}"
						@click="headerclick(2)">
						<span>{{ $t('msg.msg3') }}</span>
					</div>
				</div>
				<div class="header_three">
					<el-select v-model="value" placeholder="请选择" @change="lanagechange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: 'HelloWorld',
		data() {
			return {
				userInfo: "", //个人中心信息
				editshow: false,
				login_outshow: false, //退出登录弹窗
				headerindex: 1, //头部状态
				ContactUs: "", //平台信息
				options: [{
					value: 'zh',
					label: '中文'
				}, {
					value: 'spa',
					label: 'España'
				}],
				value: 'zh'
			}
		},
		mounted() {
			if (localStorage.getItem("headerindex")) {
				this.headerindex = localStorage.getItem("headerindex");
			} else {
				this.headerindex = 1;
			}
			if (localStorage.getItem("lang")) {
				this.value = localStorage.getItem("lang");
			} else {
				this.value = "zh";
			}
		},
		created() {
			// this.linktab(); //判断是移动端还是pc端
			this.getContactUs()
		},
		methods: {
			//语言切换
			lanagechange(e){
				console.log(e)
				if(e=="zh"){
					localStorage.setItem('lang',"zh")
				}else{
					localStorage.setItem('lang',"spa")
				}
				window.location.reload();
			},
			
			//头部切换事件
			headerclick(index) {
				localStorage.setItem("headerindex", index);
				if (index == 1) {
					this.$router.push({
						"path": "/"
					});
				} else {
					this.$router.push({
						"path": "/about"
					})
				}
			},

			//联系我们
			getContactUs() {
				this.$http({
					url: '661f907319645',
					method: 'post',
					data: JSON.stringify({

					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.ContactUs = res.data.data;
					}
				});
			},

			// linktab() {
			// 	let goUrl = this.isMobile();
			// 	if (goUrl === 1) {
			// 		//移动端地址
			// 	} else {
			// 		//PC地址
			// 		// location = "https://www.denlike.com/";
			// 	}
			// },

			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},

			//退出登录确定事件
			logincanfirem() {
				this.$message({
					message: "退出成功",
					type: 'success'
				});
				localStorage.clear();
				this.$router.push({
					path: "/"
				});
				this.login_outshow = false;
			},

			//跳转编辑资料模块
			editnext(type) {
				if (type == 1) {
					// localStorage.setItem("selfleftindex",5)
					this.$router.push({
						"path": "edituser"
					})
				} else if (type == 2) {
					localStorage.setItem("selfleftindex", 5)
					this.$router.push({
						"path": "lvshilist"
					})
				} else if (type == 3) {
					localStorage.setItem("selfleftindex", 1)
					this.$router.push({
						"path": "selfcenter"
					})
				}
			},
		},
	}
</script>
<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.login_header {
		width: 100%;
		height: 80px;
		cursor: pointer;
		background-color: #FFFFFF
	}

	.login_header_content {
		width: 1200px;
		height: 100%;
		position: relative;
		margin: 0 auto;
	}

	.login_header_one {
		width: auto;
		height: 60px;
		font-weight: 600;
		font-size: 28px;
		color: #333333;
		float: left;
		margin-top: 10px;
		margin-right: 126px;
	}

	.login_header_one img {
		width: 90px;
		height: 45px;
		margin-right: 16px;
		display: block;
		float: left;
		margin-top: 12px;
	}

	.login_header_one_text {
		width: auto;
		height: auto;
		float: left;
	}

	.login_header_one_text h3 {
		font-weight: 600;
		font-size: 14px;
		color: #1A1A1A;
		margin-top: 4px;
	}

	.login_header_one_text p {
		font-weight: 400;
		font-size: 10px;
		color: #1A1A1A;
	}

	.header_two {
		width: auto;
		float: left;
	}

	.header_two_list {
		width: auto;
		font-weight: 400;
		font-size: 24px;
		color: #999999;
		float: left;
		margin-right: 100px;
		margin-top: 10px;
		line-height: 68px;
	}

	.header_two_list_now {
		font-weight: 500;
		font-size: 24px;
		color: #DD8E33;
		border-bottom: 3px solid #DD8E33;
	}

	.header_three {
		width: auto;
		height: 100%;
		float: right;
		line-height: 80px;
	}
</style>
<template>
	<div class="fotter">
		<div class="fotter_content">
			<div class="fotter_content_one">
				<img :src="ContactUs.web_site_logo" alt="" />
				<span>{{ContactUs.web_company_name}}</span>
			</div>
			<div class="fotter_content_two">
				<div class="fotter_content_two_list">
					<img src="../assets/mc17.png" alt="" />
					<span>{{ $t('msg.msg1') }}：</span>
					<span>{{ContactUs.tel}}</span>
				</div>
				<div class="fotter_content_two_list">
					<img src="../assets/mc18.png" alt="" />
					<span>{{ContactUs.web_site_email}}</span>
				</div>
				<div class="fotter_content_two_list">
					<img src="../assets/mc19.png" alt="" />
					<span>{{ContactUs.web_site_address}}</span>
				</div>
			</div>
		</div>
		<div class="fotter_botton">
			<span>{{ContactUs.web_site_copyright}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ContactUs: "", //网站基本信息
			
			}
		},
		mounted() {
			this.getContactUs();		
		},
		methods: {
			//联系我们
			getContactUs(){
				this.$http({
					url: '666a679085dc2',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.ContactUs = res.data.data;
					}
				});
			},

		}
	}
</script>

<style scoped>
	.fotter {
		width: 100%;
		height: 200px;
		background: #171717;
	}
	.fotter_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.fotter_content_one{
		width: auto;
		overflow: hidden;
		float: left;
		margin-top: 50px;
		font-weight: 500;
		font-size: 18px;
		color: #BABABA;
	}
	.fotter_content_one img{
		width: 90px;
		height: 45px;
		margin-bottom: 15px;
		display: block;
	}
	.fotter_content_two{
		width: auto;
		overflow: hidden;
		float: left;
		margin-left: 180px;
		margin-top: 38px;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
	}
	.fotter_content_two_list {
		width: auto;
		margin-bottom: 14px;
	}
	.fotter_content_two_list img{
		width: 16px;
		height: 16px;
		position: relative;
		top: 3px;
		margin-right: 6px;
	}
	.fotter_botton{
		width: 100%;
		overflow: hidden;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-align: center;
	}
</style>
export default { 
  //英文
    msg: {
        msg1: 'Línea directa',
        msg2: 'Inicio',
        msg3: 'Contáctanos',
        msg4: 'Sin datos',
        msg5: 'Teléfono',
        msg6: 'Correo electrónico',
        msg7: 'Lista de materiales > Detalles del material',
        msg8: 'Tamaño personalizado',
        msg9: 'Por favor ingrese la longitud',
        msg10: 'Metros',
        msg11: 'Agregar',
        msg12: 'Eliminar',
        msg13: 'Por favor ingrese la altura',
        msg14: 'Cantidad',
        msg15: 'Monto a pagar',
        msg16: 'Solicitar compra',
        msg17: 'Descripción del producto',
        msg18: 'Dirección de contacto',
        msg19: 'Destinatario',
        msg20: 'Por favor ingrese el destinatario',
        msg21: 'Hombre',
        msg22: 'Mujer',
        msg23: 'Teléfono de contacto',
        msg24: 'Por favor ingrese el teléfono de contacto',
        msg25: ' ¿Necesita entrega?',
        msg26: 'Sí',
        msg27: 'No',
        msg28: 'Ubicación',
        msg29: 'Por favor seleccione',
        msg30: 'Dirección detallada',
        msg31: 'Por favor ingrese una dirección detallada: como calle, número, comunidad, edificio, etc.',
        msg32: 'Mensaje',
        msg33: 'Opcional: el contenido ingresado ya ha sido acordado con el vendedor',
        msg34: 'Enviar',
        msg35: 'Pedido exitoso',
        msg36: 'Su solicitud ha sido enviada con éxito, un representante se pondrá en contacto con usted más tarde',
        msg37: 'Contáctanos',
        msg38: 'Hora de envío',
        msg39: 'Volver al inicio',
        msg40: 'Lista de productos',
        msg41: 'Ventanas',
        msg42: 'Materiales',
        msg43: 'Ver más',
        msg44: 'Lista de productos > Detalles del producto',
        msg45: 'Color',
        msg46: 'Seleccionar',
        msg47: 'Por favor seleccione',
        msg48: 'Forma de la ventana',
        msg49: 'Aleación de aluminio',
        msg50: 'Tamaño personalizado',
        msg51: 'Por favor ingrese la altura',
        msg52: 'Por favor ingrese el ancho',
        msg53: '¿Necesita vidrio?',
        msg54: 'Nombre del vidrio',
        msg55: 'Por favor seleccione',
        msg56: 'Accesorios',
        msg57: 'Cantidad:',
        msg58: 'Monto a pagar',
        msg59: 'Solicitar compra',
        msg60: 'Descripción del producto',
        msg61: 'Seleccionar color',
        msg62: 'Cerrar',
        msg63: 'Confirmar',
        msg64: 'Por favor ingrese palabras clave para buscar',
        msg65: 'Buscar',
        msg66: 'Yuanes/Metro',
        msg67: 'Seleccionado',
        msg68: 'Seleccionar',
        msg69: 'Seleccionar forma de la ventana',
        msg70: 'Selección de aleación de aluminio',
        msg71: 'Seleccionar vidrio',
        msg72: 'Por favor ingrese el destinatario',
        msg73: 'Por favor ingrese el teléfono de contacto',
        msg74: 'Volver al inicio',
		msg75:  "Piezas",
		msg76:  "Número mínimo 1",
		msg77:  "Por favor, elija las especificaciones primero.",
		msg78:  "Introduzca la longitud del tamaño personalizado",
		msg79:  "Introduzca el ancho del tamaño personalizado",
		msg80:  "Introduzca la altura del tamaño personalizado",
		msg81:  "Por favor, introduzca el destinatario",
		msg82:  "Por favor, introduzca el número de contacto",
		msg83:  "Por favor, elija su región",
		msg84:  "Por favor, rellene la dirección detallada",
		msg85:  "Por favor, elija el color",
		msg86:  "Por favor, elija la forma de la ventana",
		msg87:  "Por favor, elija aleación de aluminio",
		msg88:  "Por favor, elija el vidrio",
		msg89:  "Selección completa",
    }
}
